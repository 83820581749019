<template>
  <section>
    <div class="background-section-title">
      <div class="section-title">
        <h2>Usuários</h2>
      </div>
    </div>
      <div class="section-content">
        <div class="list-container">
          <div class="vld-parent">
            <loading :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"></loading>
          </div>
          <div class="header-container shadow-sm p-3 mb-5 bg-white rounded">
            <b-button class="new-user-button" @click="newUser()">
              Cadastrar usuário
            </b-button>
            <div class="search-container">
              <b-input-group size="sm" class="mb-0">
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input v-model="filterText" type="search" placeholder="Buscar..."></b-form-input>
              </b-input-group>
            </div>
          </div>
          <b-table class="shadow-sm bg-white rounded" :fields="fields" :items="filteredUsers" @filtered="onFiltered" :per-page="perPage" :current-page="currentPage">
            <template v-slot:cell(edit)="row">
              <img class="item-table" :src="editIcon" @click="editUser(row.item, row.index, $event.target)">
            </template>
            <template v-slot:cell(remove)="row">
              <img class="item-table" :src="deleteIcon" @click="deleteUser(row.item, row.index, $event.target)">
            </template>
          </b-table>
          <div class="pagination-container shadow-sm mb-5 bg-white rounded">
            <b-row>
              <b-col class="my-3">
                <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                ></b-pagination>
              </b-col>
              <b-col  class="my-3">
                  <b-form-select
                          style="min-width: 100px"
                          id="per-page-select"
                          v-model="perPage"
                          :options="pageOptions"
                  ></b-form-select>
              </b-col>
            </b-row>
          </div>
        </div>
        <b-modal
            id="modal-prevent-closing"
            ref="modal"
            :ok-title="editing ? 'Concluir' : 'Concluir'"
            cancel-title="Cancelar"
            cancel-variant="transparent"
            :title="modalTitle"
            modal-class="edit-title"
            @hidden="resetModal"
            @ok="handleModalOk"
            size="lg"
          >
            <b-form @submit.stop.prevent="handleSubmit">
              <b-row>
                <b-col>
                  <b-form-group label="E-mail" label-for="email-input" style="font-weight: 600" :state="emailState" invalid-feedback="Email com formato inválido">
                    <b-form-input id="email-input" :readonly="editing" :state="emailState" v-model="user.login" placeholder="Digite o e-mail..." class="border border-dark" autofocus></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
              <b-form-group label="Permissões">
                <b-form-checkbox-group
                  id="permissionGroup"
                  v-model="selectedPermissions"
                  :options="permissionsOptions"
                  stacked
                ></b-form-checkbox-group>
              </b-form-group>
                </b-col>
              </b-row>
            </b-form>
        </b-modal>
      </div>
  </section>
</template>

<script>
  import { userService } from '../services/user_service';
  import { authorizationService } from '../services/authorization.service';
  import searchIcon from '@/assets/icons/magnify.svg';
  import deleteIcon from '@/assets/icons/delete.svg';
  import editIcon from '@/assets/icons/pencil.svg';
  import moment from 'moment';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  export default {
    components: {
      Loading
    },
    data () {
      return {
        searchIcon,
        deleteIcon,
        editIcon,
        sortDesc: false,
        sortBy: '',
        sortDirection: 'asc',
        filterOn: [],
        filter: null,
        perPage: 5,
        currentPage: 1,
        totalRows: 1,
        isBusy: false,
        filterText: '',
        emptyText: 'Não há registros',
        pageOptions: [5, 10, 15, { value: 100, text: 'Mostrar 100' }],
        selectedPermissions: [],
        permissionsOptions: [],
        fields: [
          { key: 'name', label: 'Nome', sortable: false },
          { key: 'login', label: 'Login', sortable: false },
          {
            key: 'status',
            label: 'Situação',
            sortable: false,
            formatter: (value, key, item) => {
              return value ? 'Confirmado' : 'Pendente';
            }
          },
          {
            key: 'admin',
            label: 'Admin',
            sortable: false,
            formatter: (value, key, item) => {
              return value ? 'Sim' : 'Não';
            },
            thStyle: { width: '120px' }
          },
          { key: 'edit', label: '', sortable: false, thStyle: { width: '50px' } },
          { key: 'remove', label: '', sortable: false, thStyle: { width: '100px' } }
        ],
        user: {
          login: ''
        },
        submitted: false,
        userInfo: undefined,
        modalTitle: 'Novo usuário',
        emailState: null,
        isLoading: false,
        fullPage: true,
        editing: false
      };
    },
    async created () {
      await this.loadUsers();
    },
    watch: {
      'user.login' () {
        this.emailState = this.validateEmail(this.user.login);
      }
    },
    methods: {
      async loadUsers () {
        this.isLoading = true;
        this.userInfo = await userService.userInfo();
        if (this.userInfo && this.userInfo.organization && this.userInfo.organization.users) {
          this.totalRows = this.userInfo.organization.users.length;
        }
        this.isLoading = false;
      },
      checkFormValidity () {
        this.emailState = this.validateEmail(this.user.login);
        if (this.editing) {
          if (this.selectedPermissions.length === 0) {
            this.$toast.open({ message: 'Ao menos uma permissão deve ser selecionada', type: 'error' });
            return false;
          }
        }
        return this.emailState;
      },
      validateEmail (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
      async handleSubmit (e) {
        this.submitted = true;
        if (!this.checkFormValidity()) {
          return;
        }
        if (this.editing) {
          Object.assign(this.user, { corporateId: this.userInfo.organization.id });
          Object.assign(this.user, { permissions: this.selectedPermissions, roles: [] });
          await userService.update(this.user);
          this.editing = false;
          this.$toast.open('Cadastro alterado com sucesso!');
        } else {
          Object.assign(this.user, { corporateId: this.userInfo.organization.id });
          Object.assign(this.user, { permissions: this.selectedPermissions });
          const res = await userService.register(this.user);
          if (res) {
            this.$toast.open('Usuário convidado com sucesso!');
          } else {
            this.$toast.open({ message: 'Não foi possivel convidar usuário!', type: 'error', duration: 4000 });
          }
        }
        this.isLoading = true;
        this.userInfo = await userService.userInfo();
        this.isLoading = false;
        this.$nextTick(() => {
          this.$refs.modal.hide();
        });
      },
      onFiltered (filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      resetModal () {
        this.user.enabled = false;
        this.emailState = null;
        this.editing = false;
      },
      handleModalOk (bvModalEvt) {
        bvModalEvt.preventDefault();
        this.handleSubmit();
      },
      async newUser () {
        this.user.login = '';
        const permissions = this.$store.state.account.permissions;
        if (permissions && !permissions.includes('CRIAR_USUARIO') && !this.$store.state.account.isAdmin) {
          this.$toast.open({ message: 'Usuário não possui permissão!', type: 'error' });
          return;
        }
        this.permissionsOptions = [];
        this.selectedPermissions = [];
        const defaultPermissions = authorizationService.defaultPermissions();
        for (const key of Object.keys(defaultPermissions)) {
          this.permissionsOptions.push({ text: defaultPermissions[key], value: key });
        }
        this.modalTitle = 'Cadastrar usuário';
        this.$refs.modal.show();
      },
      async editUser (item, index, button) {
        if (!item.status) {
          this.$toast.open({ message: 'Usuário não confirmado!', type: 'error' });
          return;
        }
        const stateAccount = this.$store.state.account;
        const isAdmin = stateAccount.isAdmin;
        const itemTableIsAdmin = item.admin;
        if (!isAdmin && itemTableIsAdmin) {
          this.$toast.open({ message: 'Usuário é admin e não pode ser editado!', type: 'error' });
          return;
        }
        this.editing = true;
        this.user.login = item.login;
        this.user.enabled = item.enabled;
        this.modalTitle = 'Editar usuário';
        this.permissionsOptions = [];
        this.selectedPermissions = [];
        const { permissions } = this.userInfo.organization.users.filter(user => {
          return item.login === user.login;
        })[0];
        if (stateAccount.permissions && !stateAccount.permissions.includes('EDITAR_USUARIO') && !stateAccount.isAdmin) {
          this.$toast.open({ message: 'Usuário não possui permissão!', type: 'error' });
          return;
        }
        const defaultPermissions = authorizationService.defaultPermissions();
        for (var key of Object.keys(defaultPermissions)) {
          this.permissionsOptions.push({ text: defaultPermissions[key], value: key });
          permissions.filter(item => {
            if (item === key) {
              this.selectedPermissions.push(item);
            }
          });
        }
        this.$refs.modal.show();
      },
      async deleteUser (item, index, button) {
        if (item.admin) {
          this.$toast.open({ message: 'Usuário é admin e não pode ser removido!', type: 'error' });
          return;
        }
        if (this.$store.state.account.permissions && !this.$store.state.account.permissions.includes('EXCLUIR_USUARIO') && !this.$store.state.account.isAdmin) {
          this.$toast.open({ message: 'Usuário não possui permissão!', type: 'error' });
          return;
        }
        if (item.login === this.$store.state.account.user.username) {
          this.$toast.open({ message: 'Não é possivel remover o usuário atual!', type: 'error' });
          return;
        }
        this.$bvModal.msgBoxConfirm(`Tem certeza que deseja remover ${item.login} ?`, {
          title: 'Remover usuário',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async value => {
            if (value) {
              await userService.remove(item.login, false);
              await this.loadUsers();
              this.userInfo = await userService.userInfo();
              if (this.userInfo) {
                this.$toast.open('Usuário excluído com sucesso!');
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      async enableUser (item, index, check) {
        if (!item.status) {
          this.$toast.open({ message: 'Usuário não confirmado!', type: 'error' });
          item.enabled = false;
          return;
        }
        if (item.admin) {
          this.$toast.open({ message: 'Usuário é admin!', type: 'error' });
          item.enabled = true;
          return;
        }
        await userService.update(item);
      },
      getFormatDate (value) {
        return moment(Number(value)).format('DD/MM/YYYY');
      }
    },
    computed: {
      filteredUsers: function () {
        const users = [];
        if (this.userInfo && this.userInfo.organization && this.userInfo.organization.users) {
          this.userInfo.organization.users.filter(item => {
            users.push({ login: item.login, name: item.name, status: item.trialId, admin: item.isAdmin, enabled: item.active });
          });
        }
        return users.filter(item => {
          if (this.filterText.length > 2) {
            return item.login.toLowerCase().includes(this.filterText.toLowerCase());
          } else {
            return item;
          }
        });
      }

    }
  };
</script>

<style lang="scss" scoped>
  .section-content {
    display: flex;

    .overlay {
        position: absolute;
        top: 5px;
        left: 10px;

        span {
          color: #ccc;
          font-size: 18px;
        }
    }

    .list-container {
      margin-left: 20px;
      margin-right: 20px;
      flex: 1;

      .header-container {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #F5F8FA;
        border-radius: 4px;

        h3 {
          margin: 0;
          font-size: 20px;
          font-weight: 400;
        }

        .search-container {
          margin-right: 4%;
        }
      }

      .table {
        margin-top: 15px;
        background-color: #FFFFFF;
        border-radius: 4px;
        margin-bottom: 0px;
        /deep/thead th {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          padding-left: 0.8rem;
          line-height: 40px;
          letter-spacing: 0em;
          text-align: left;
          background: #F5F8FA;
          color: #6278A3;

          border-radius: 4px 4px 0px 0px;
          padding: 0.5rem;
          padding-left: 24px;
        }

        /deep/tbody td {
          font-size: 14px;
          padding-top: 0.5rem;
          font-size: 14px;
          font-style: normal;
          color: #6278A3;
          font-weight: 400;
          line-height: 40px;
          letter-spacing: 0em;
          text-align: left;
          padding-left: 24px;
        }
      }

      .item-table {
        border-radius: 4px;
        background-color: #FFFFFF;
        cursor: pointer;
      }

      .new-user-button {
        margin-left: 2%;
        background: #207BDD;
        border-radius: 4px;
        border-color: #207BDD;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;

        &:hover {
            background-color: #b5dcb1;
            transition: background-color 0.2s linear;
          }
      }
    }
  }

  /deep/ .edit-title > .modal-dialog > .modal-content {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #6278A3;
    .pt-0 {
      font-weight: 600;
    }
  }

  .modal-content .modal-body {
    color: #207BDD;
  }

  .pagination-container {
    background-color: #FFFFFF;
    display: flex;
    flex-flow: row;
    align-content: center;
    padding-left: 35%;
  }
  /deep/.modal-footer .btn-primary {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  /deep/.modal-header h5 {
    color: #004A78;
  }
  /deep/.modal-footer .btn-transparent {
    background-color: #e5e5e5;
    color: #007bff;
    font-weight: 500;
  }
</style>
